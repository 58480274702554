/**
 * Defines the main color of the shop theme. Usage: primary elements' background and color.
 */
/**
 * Defines alternative color of the shop theme. Usage: secondary elements' background and color.
 */
/**
 * Defines white color. Usage: not transparent elements' background of default background.
 */
/**
 * Defines a light shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines a lighter shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines the lightest shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines the shade of the black color. Usage: as a base of black color for semi-transparent elements.
 */
/**
 * Defines a dark shade color. Usage: elements' text color variant.
 */
/**
 * Defines a darker shade color. Usage: elements' text color variant.
 */
/**
 * Defines the darkest shade color. Usage: elements' text color variant.
 */
/**
 * Defines a transparent color. Usage: elements' background, border.
 */
/**
 * Defines the main background color. Usage: body and side-drawer background.
 */
/**
 * Defines the main color of text. Usage: default global text color.
 */
/**
 * Defines a default global border color for the input elements.
 */
/**
 * Defines a default global border color for the input elements on focus event.
 */
/**
 * Defines a shadow shade and transparency for the shadowed elements.
 */
/**
 * Defines a default overlay color. Usage: elements with overlay - side-drawer, popups etc.
 */
/**
 * Defines a default image overlay color. Usage: images with overlay - lazy-image.
 */
/**
 * Defines action colors map.
 */
/**
 * Defines font family setting. Usage: body global font styles.
 */
/**
 * Defines font family setting for code. Can be used for code and pre elements.
 */
/**
 * Defines basic font size. Usage: global basic font size.
 */
/**
 * Font size values map. Usage: mixins providing styles for headings, text elements etc.
 */
/**
 * Font weight values map. Usage: mixin to set font weight property.
 */
/**
 * Font line height values map. Usage: mixins providing styles for headings, text elements etc.
 */
/**
 * Defines a default duration value for the animation effects. Usage: mixins providing animation properties.
 */
/**
 * Defines a default timing function for the animation effects. Usage: mixins providing animation properties.
 */
/**
 * Defines default z-index value.
 */
/**
 * Defines z-index value for ajax dynamic loader.
 */
/**
 * Defines z-index value for the floating elements.
 */
/**
 * Defines z-index value for the header context.
 */
/**
 * Defines z-index value for the side drawer context.
 */
/**
 * Defines z-index value for the popup.
 */
/**
 * Defines z-index value for the overlay.
 */
/**
 * Defines z-index value for the notification area context.
 */
/**
 * Defines z-index value for the search suggestions context.
 */
/**
 * Defines z-index value for the ajax loader.
 */
/**
 * Defines z-index value for the top navigation context.
 */
/**
 * Defines spacing values map. Usage: spacing helpers and mixins.
 */
/**
 * Defines breakpoints' values for responsive.
 */
/**
 * Defines grid system columns count.
 */
/**
 * Defines grid container max width.
 */
/**
 * Defines gutters' size for grid "with-gutter" modifier.
 */
/**
 * Defines import blacklist. Usage: import helper.
 */
/**
 * Provides list of properies for reset usage.
 */
/**
 * Wrapper function for the darken sass function with a default degree parameter.
 * @param {Color} $setting-color
 * @param {Number} $degree [10]
 */
/**
 * Wrapper function for the lighten sass function with a default degree parameter.
 * @param {Color} $setting-color
 * @param {Number} $degree [10]
 */
/**
 * Sets font-size property from font size settings map - $setting-font-size according to the passed map key.
 * @param {String | Number} $size
 */
/**
 * Sets font-weight property from font weight settings map - $setting-font-weight according to the passed map key.
 * @param {String} $weight
 */
/**
 * Sets line-height property from line-height settings map - $setting-font-line-height according to the passed map key.
 * @param {String | Number} $height
 */
/**
 * Povides transition property configured by the passed parameters.
 * @param {String} $props
 * @param {Number} $duration [$setting-effect-default-duration]
 * @param {String} $ease [$setting-effect-default-ease]
 */
/**
 * Forces element visibility.
 */
/**
 * Forces element invisibility.
 */
/**
 * Provides global class names with spacing properties set from $setting-spacing map.
 * @param {String} $name Main selector name.
 * @param {List} $sides [[]] List of sides to set properties.
 */
/**
 * Returns 0 or the first value from the $edges list.
 * @param {List} $edges [$setting-breakpoints] List length must be less than or equal to 2.
 * @return {Base}
 */
/**
 * Returns null or the last value from the $edges list.
 * @param {List} $edges [$setting-breakpoints] List length must be less than or equal to 2.
 * @return {Base}
 */
/**
 * Sets screen media query with min-width set to $min parameter.
 * @param $min {Number} [0]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with breakpoints range passed as parameters.
 * @param {Number} $min [0]
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with max-width set to $max parameter.
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with breakpoints range passed to parameters or
 * with min-width query if only one parameter is passed.
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Returns column ratio in relation to columns count.
 * @param {Number} $column
 * @param {Number} $columns [$setting-grid-columns]
 * @return {Number}
 */
/**
 * Adds clearing for element with floated child elements.
 */
/**
 * Adds a box-shadow with basic values.
 */
/**
 * Checks parameter on presence in the blacklist of imports setting list.
 * @param {List} $keywords
 * @content Extends mixin with properties if $keywords is not on the blacklist.
 */
.center {
  text-align: center;
}

.pt20 {
  padding-top: 1.25rem;
}

@media print {
  .print-hidden {
    display: none;
  }
}

.print-visible {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
@media print {
  .print-visible {
    visibility: visible;
    height: auto;
    overflow: auto;
  }
}

@media print {
  .col--print-1 {
    width: calc((100% / 12) * 1);
    float: left;
  }
  .col--print-2 {
    width: calc((100% / 12) * 2);
    float: left;
  }
  .col--print-3 {
    width: calc((100% / 12) * 3);
    float: left;
  }
  .col--print-4 {
    width: calc((100% / 12) * 4);
    float: left;
  }
  .col--print-5 {
    width: calc((100% / 12) * 5);
    float: left;
  }
  .col--print-6 {
    width: calc((100% / 12) * 6);
    float: left;
  }
  .col--print-7 {
    width: calc((100% / 12) * 7);
    float: left;
  }
  .col--print-8 {
    width: calc((100% / 12) * 8);
    float: left;
  }
  .col--print-9 {
    width: calc((100% / 12) * 9);
    float: left;
  }
  .col--print-10 {
    width: calc((100% / 12) * 10);
    float: left;
  }
  .col--print-11 {
    width: calc((100% / 12) * 11);
    float: left;
  }
  .col--print-12 {
    width: calc((100% / 12) * 12);
    float: left;
  }

  .usercentrics-button, .slick-arrow, .product-compare__item-remove {
    display: none;
  }
}
@media print {
  .order-detail-rma .header {
    display: none;
  }
}

/**
 * Adds a small arrow on the element for the configured direction.
 */
/**
 * Defines import blacklist. Usage: import helper.
 */
/**
 * @deprecated Use agent-widget-agent-navigation mixin instead.
 */
/**
 * @deprecated Use agent-widget-agent-control-bar mixin instead.
 */
.summary-overview__grand-total-price {
  font-size: 1.1rem;
  line-height: 1.4;
}

/**
 * @deprecated Use cms-search-page-sort mixin instead.
 */
/**
 * @deprecated Use company-page-business-unit-chart-item mixin instead.
 */
/**
 * @deprecated Use company-user-agent-widget-company-user-list mixin instead.
 */
/**
 * @deprecated Use file-manager-widget-file-download-link mixin instead.
 */
/**
 * @deprecated Use price-product-volume-widget-volume-price mixin instead.
 */
/**
 * @deprecated Use price-product-volume-widget-volume-price-table mixin instead.
 */
/**
 * @deprecated Use product-option-widget-shopping-list-product-option-list mixin instead.
 */
/**
 * @deprecated Use product-packaging-unit-widget-packaging-unit-cart mixin instead.
 */
/**
 * @deprecated Use product-review-widget-rating-selector mixin instead.
 */
/**
 * @deprecated Use product-search-widget-product-quick-add-form mixin instead.
 */
/**
 * @deprecated Use configurable-bundle-widget-order-configured-bundle mixin instead.
 */
/**
 * @deprecated Use configurable-bundle-widget-order-configured-bundle-product mixin instead.
 */
.collapsible-list__button {
  background-color: rgba(255, 255, 255, 0);
  color: #E30613;
  cursor: pointer;
}
.collapsible-list__button:hover {
  text-decoration: underline;
}
.collapsible-list__button::after {
  content: attr(data-show);
}
.collapsible-list__button--shown::after {
  content: attr(data-hide);
}
.collapsible-list__button-content {
  display: none;
}

/* fe-sniffer:disabled correct-scss-component-structure */
/**
 * @deprecated Use shop-ui-quote-status-color mixin instead.
 */
/**
 * @deprecated Use tabs-widget-search-tabs mixin instead.
 */
.icon {
  fill: currentColor;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.icon--small {
  width: 0.75rem;
  height: 0.75rem;
}
.icon--smaller {
  width: 0.5rem;
  height: 0.5rem;
}
.icon--big {
  width: 1.25rem;
  height: 1.25rem;
}
.icon--bigger {
  width: 1.5rem;
  height: 1.5rem;
}
.icon--biggest {
  width: 3rem;
  height: 3rem;
}
.icon--logo {
  width: 6rem;
  height: 2rem;
  max-width: 100%;
}
@media only screen and (min-width: 768px) {
  .icon--logo {
    width: 10.9375rem;
    height: 4.375rem;
  }
}
@media only screen and (min-width: 1024px) {
  .icon--logo {
    width: 12.1875rem;
  }
}
.icon--checkout {
  width: 6.8125rem;
  height: 2rem;
}
@media only screen and (min-width: 768px) {
  .icon--checkout {
    width: 10.4375rem;
    height: 3.75rem;
  }
}
.icon--header-check {
  width: 0.75rem;
  height: 0.5625rem;
  margin: -0.125rem 0.5625rem 0 0;
}
.icon--progress-check {
  width: 0.4375rem;
  height: 0.3125rem;
}
@media only screen and (min-width: 550px) {
  .icon--progress-check {
    width: 0.625rem;
    height: 0.5rem;
  }
}
.icon--phone {
  width: 0.9375rem;
  height: 0.9375rem;
  margin-right: 0.375rem;
}
.icon--search {
  width: 1rem;
  height: 1rem;
  stroke-width: 0.03125rem;
}
.icon--search-result {
  fill: #666666;
  margin-right: 0.25rem;
}
.icon--search-result__tag {
  margin-right: 0.25rem;
}
.icon--user {
  width: 1.125rem;
  height: 1.3125rem;
  margin-top: 0.4375rem;
}
.icon--quick-order {
  width: 1.3125rem;
  height: 0.9375rem;
}
@media only screen and (min-width: 768px) {
  .icon--quick-order {
    width: 2rem;
    height: 1.1875rem;
    margin-top: 0.1875rem;
  }
}
.icon--quick-order-delete-row {
  width: 0.875rem;
  height: 0.9375rem;
  fill: #666666;
}
.icon--header-shopping-list {
  width: 0.8125rem;
  height: 0.75rem;
}
@media only screen and (min-width: 768px) {
  .icon--header-shopping-list {
    width: 1.0625rem;
    height: 1rem;
    margin-top: 0.625rem;
  }
}
.icon--cart {
  width: 1.25rem;
  height: 1.0625rem;
}
.icon--contact {
  width: 1.375rem;
  height: 1.5rem;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 768px) {
  .icon--contact {
    display: none;
  }
}
.icon--cross {
  width: 0.625rem;
  height: 0.625rem;
}
.icon--reverse {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.icon--toggler-cross {
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
}
.icon--toggler-cross::before, .icon--toggler-cross::after {
  content: "";
  position: absolute;
  background-color: #666666;
}
.icon--toggler-cross::before {
  height: 0.125rem;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media only screen and (min-width: 768px) {
  .icon--toggler-cross::before {
    height: 0.1875rem;
  }
}
.icon--toggler-cross::after {
  height: 100%;
  width: 0.125rem;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media only screen and (min-width: 768px) {
  .icon--toggler-cross::after {
    width: 0.1875rem;
  }
}
.active .icon--toggler-cross::after {
  display: none;
}
.icon--toggler-cross-thin::before {
  height: 0.125rem;
}
.icon--toggler-cross-thin::after {
  width: 0.125rem;
}
.icon--cart-plus {
  display: inline-block;
  width: 1.625rem;
  height: 1.4375rem;
  margin: -0.375rem 0.75rem -0.125rem 0;
}
.icon--toggler-cross-link::before, .icon--toggler-cross-link::after {
  background-color: #8f8f8f;
}
.icon--link {
  -webkit-transition: color 250ms ease-in-out;
  transition: color 250ms ease-in-out;
  color: #666666;
  margin-right: 0.375rem;
}
.icon--shipment {
  width: 5.1875rem;
}
.icon--tof-logo, .icon--tof-exp-logo {
  width: 6rem;
  margin-top: -0.125rem;
}
.icon--ups-logo, .icon--upsam-logo {
  width: 2rem;
  height: 2rem;
}
.icon--dhl-logo {
  width: 4.5625rem;
}
.icon--fedex-logo {
  width: 3.25rem;
  margin-top: -0.125rem;
}
.icon--invoice-logo {
  width: 3.25rem;
  height: 1.25rem;
}
@media only screen and (min-width: 768px) {
  .icon--invoice-logo {
    width: 5.1875rem;
    height: 1.75rem;
  }
}
.icon--invoice-logo-small {
  width: 3.4375rem;
  height: 1.375rem;
}
.icon--payment-logo {
  position: absolute;
  top: 1.6875rem;
  right: 1.25rem;
}
@media only screen and (min-width: 768px) {
  .icon--payment-logo {
    top: 1.6875rem;
    right: 1.875rem;
  }
}
.icon--summary-shipping {
  width: 3.3125rem;
}
.icon--overview {
  width: 1.0625rem;
  height: 1rem;
}
.icon--users {
  width: 1.8125rem;
  height: 1.1875rem;
}
.icon--business-unit {
  width: 1.375rem;
  height: 1.375rem;
}
.icon--role {
  height: 0.875rem;
}
.icon--plus-small {
  width: 0.625rem;
  height: 0.625rem;
  position: relative;
  top: -0.0625rem;
}
.icon--share {
  width: 1.25rem;
}
.icon--add-to-list {
  position: relative;
  top: 0.0625rem;
  width: 1.625rem;
  height: 1.625rem;
}
.icon--success {
  width: 7.5rem;
  height: 7.5rem;
  margin-bottom: -1.375rem;
}
.icon--footer {
  height: 1.75rem;
  width: 5.8125rem;
}
.icon--invoice-logo-footer {
  width: 4.5625rem;
}
.icon--pay-pal-logo-footer {
  width: 4rem;
}
.icon--klarna-logo-footer {
  width: 4.25rem;
}
.icon--dhl-logo-footer {
  width: 4.75rem;
}
.icon--copyright {
  width: 6.625rem;
  height: 3rem;
}
.icon--reset {
  -webkit-transition: color 250ms ease-in-out;
  transition: color 250ms ease-in-out;
  margin-right: 0.5rem;
  color: #666666;
}
.icon--chevron {
  width: 0.625rem;
  height: 0.625rem;
}
.icon--create-list {
  vertical-align: bottom;
  height: 1.125rem;
  width: 1.125rem;
}
.icon--create-new-cart {
  width: 1.5rem;
  height: 1.125rem;
  vertical-align: bottom;
}
.icon--trolley {
  width: 1.25rem;
}
.icon--title {
  width: 2.1875rem;
  height: 1.625rem;
}
.icon--sm-lg-regular {
  width: 1rem;
  height: 1rem;
}
@media only screen and (min-width: 768px) {
  .icon--sm-lg-regular {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.icon--sm-lg-bigger {
  width: 1rem;
  height: 1rem;
}
@media only screen and (min-width: 768px) {
  .icon--sm-lg-bigger {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.icon--browse-file {
  width: 1.75rem;
  height: 1.75rem;
}
.icon--download-file {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.875rem;
  height: 1.875rem;
}
.icon--remove-file {
  -webkit-transition: color 250ms ease-in-out;
  transition: color 250ms ease-in-out;
  margin: -0.125rem 0 0 0.3125rem;
  cursor: pointer;
}
.icon--remove-file:hover {
  color: #666666;
}
.icon--clock {
  width: 0.75rem;
  height: 0.75rem;
}