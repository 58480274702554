@charset "UTF-8";
/**
 * Defines the main color of the shop theme. Usage: primary elements' background and color.
 */
/**
 * Defines alternative color of the shop theme. Usage: secondary elements' background and color.
 */
/**
 * Defines white color. Usage: not transparent elements' background of default background.
 */
/**
 * Defines a light shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines a lighter shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines the lightest shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines the shade of the black color. Usage: as a base of black color for semi-transparent elements.
 */
/**
 * Defines a dark shade color. Usage: elements' text color variant.
 */
/**
 * Defines a darker shade color. Usage: elements' text color variant.
 */
/**
 * Defines the darkest shade color. Usage: elements' text color variant.
 */
/**
 * Defines a transparent color. Usage: elements' background, border.
 */
/**
 * Defines the main background color. Usage: body and side-drawer background.
 */
/**
 * Defines the main color of text. Usage: default global text color.
 */
/**
 * Defines a default global border color for the input elements.
 */
/**
 * Defines a default global border color for the input elements on focus event.
 */
/**
 * Defines a shadow shade and transparency for the shadowed elements.
 */
/**
 * Defines a default overlay color. Usage: elements with overlay - side-drawer, popups etc.
 */
/**
 * Defines a default image overlay color. Usage: images with overlay - lazy-image.
 */
/**
 * Defines action colors map.
 */
/**
 * Defines font family setting. Usage: body global font styles.
 */
/**
 * Defines font family setting for code. Can be used for code and pre elements.
 */
/**
 * Defines basic font size. Usage: global basic font size.
 */
/**
 * Font size values map. Usage: mixins providing styles for headings, text elements etc.
 */
/**
 * Font weight values map. Usage: mixin to set font weight property.
 */
/**
 * Font line height values map. Usage: mixins providing styles for headings, text elements etc.
 */
/**
 * Defines a default duration value for the animation effects. Usage: mixins providing animation properties.
 */
/**
 * Defines a default timing function for the animation effects. Usage: mixins providing animation properties.
 */
/**
 * Defines default z-index value.
 */
/**
 * Defines z-index value for ajax dynamic loader.
 */
/**
 * Defines z-index value for the floating elements.
 */
/**
 * Defines z-index value for the header context.
 */
/**
 * Defines z-index value for the side drawer context.
 */
/**
 * Defines z-index value for the popup.
 */
/**
 * Defines z-index value for the overlay.
 */
/**
 * Defines z-index value for the notification area context.
 */
/**
 * Defines z-index value for the search suggestions context.
 */
/**
 * Defines z-index value for the ajax loader.
 */
/**
 * Defines z-index value for the top navigation context.
 */
/**
 * Defines spacing values map. Usage: spacing helpers and mixins.
 */
/**
 * Defines breakpoints' values for responsive.
 */
/**
 * Defines grid system columns count.
 */
/**
 * Defines grid container max width.
 */
/**
 * Defines gutters' size for grid "with-gutter" modifier.
 */
/**
 * Defines import blacklist. Usage: import helper.
 */
/**
 * Provides list of properies for reset usage.
 */
/**
 * Wrapper function for the darken sass function with a default degree parameter.
 * @param {Color} $setting-color
 * @param {Number} $degree [10]
 */
/**
 * Wrapper function for the lighten sass function with a default degree parameter.
 * @param {Color} $setting-color
 * @param {Number} $degree [10]
 */
/**
 * Sets font-size property from font size settings map - $setting-font-size according to the passed map key.
 * @param {String | Number} $size
 */
/**
 * Sets font-weight property from font weight settings map - $setting-font-weight according to the passed map key.
 * @param {String} $weight
 */
/**
 * Sets line-height property from line-height settings map - $setting-font-line-height according to the passed map key.
 * @param {String | Number} $height
 */
/**
 * Povides transition property configured by the passed parameters.
 * @param {String} $props
 * @param {Number} $duration [$setting-effect-default-duration]
 * @param {String} $ease [$setting-effect-default-ease]
 */
/**
 * Forces element visibility.
 */
/**
 * Forces element invisibility.
 */
/**
 * Provides global class names with spacing properties set from $setting-spacing map.
 * @param {String} $name Main selector name.
 * @param {List} $sides [[]] List of sides to set properties.
 */
/**
 * Returns 0 or the first value from the $edges list.
 * @param {List} $edges [$setting-breakpoints] List length must be less than or equal to 2.
 * @return {Base}
 */
/**
 * Returns null or the last value from the $edges list.
 * @param {List} $edges [$setting-breakpoints] List length must be less than or equal to 2.
 * @return {Base}
 */
/**
 * Sets screen media query with min-width set to $min parameter.
 * @param $min {Number} [0]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with breakpoints range passed as parameters.
 * @param {Number} $min [0]
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with max-width set to $max parameter.
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with breakpoints range passed to parameters or
 * with min-width query if only one parameter is passed.
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Returns column ratio in relation to columns count.
 * @param {Number} $column
 * @param {Number} $columns [$setting-grid-columns]
 * @return {Number}
 */
/**
 * Adds clearing for element with floated child elements.
 */
/**
 * Adds a box-shadow with basic values.
 */
/**
 * Checks parameter on presence in the blacklist of imports setting list.
 * @param {List} $keywords
 * @content Extends mixin with properties if $keywords is not on the blacklist.
 */
.center {
  text-align: center;
}

.pt20 {
  padding-top: 1.25rem;
}

@media print {
  .print-hidden {
    display: none;
  }
}

.print-visible {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
@media print {
  .print-visible {
    visibility: visible;
    height: auto;
    overflow: auto;
  }
}

@media print {
  .col--print-1 {
    width: calc((100% / 12) * 1);
    float: left;
  }
  .col--print-2 {
    width: calc((100% / 12) * 2);
    float: left;
  }
  .col--print-3 {
    width: calc((100% / 12) * 3);
    float: left;
  }
  .col--print-4 {
    width: calc((100% / 12) * 4);
    float: left;
  }
  .col--print-5 {
    width: calc((100% / 12) * 5);
    float: left;
  }
  .col--print-6 {
    width: calc((100% / 12) * 6);
    float: left;
  }
  .col--print-7 {
    width: calc((100% / 12) * 7);
    float: left;
  }
  .col--print-8 {
    width: calc((100% / 12) * 8);
    float: left;
  }
  .col--print-9 {
    width: calc((100% / 12) * 9);
    float: left;
  }
  .col--print-10 {
    width: calc((100% / 12) * 10);
    float: left;
  }
  .col--print-11 {
    width: calc((100% / 12) * 11);
    float: left;
  }
  .col--print-12 {
    width: calc((100% / 12) * 12);
    float: left;
  }

  .usercentrics-button, .slick-arrow, .product-compare__item-remove {
    display: none;
  }
}
@media print {
  .order-detail-rma .header {
    display: none;
  }
}

/**
 * Adds a small arrow on the element for the configured direction.
 */
/**
 * Defines import blacklist. Usage: import helper.
 */
/**
 * @deprecated Use agent-widget-agent-navigation mixin instead.
 */
/**
 * @deprecated Use agent-widget-agent-control-bar mixin instead.
 */
.summary-overview__grand-total-price {
  font-size: 1.1rem;
  line-height: 1.4;
}

/**
 * @deprecated Use cms-search-page-sort mixin instead.
 */
/**
 * @deprecated Use company-page-business-unit-chart-item mixin instead.
 */
/**
 * @deprecated Use company-user-agent-widget-company-user-list mixin instead.
 */
/**
 * @deprecated Use file-manager-widget-file-download-link mixin instead.
 */
/**
 * @deprecated Use price-product-volume-widget-volume-price mixin instead.
 */
/**
 * @deprecated Use price-product-volume-widget-volume-price-table mixin instead.
 */
/**
 * @deprecated Use product-option-widget-shopping-list-product-option-list mixin instead.
 */
/**
 * @deprecated Use product-packaging-unit-widget-packaging-unit-cart mixin instead.
 */
/**
 * @deprecated Use product-review-widget-rating-selector mixin instead.
 */
/**
 * @deprecated Use product-search-widget-product-quick-add-form mixin instead.
 */
/**
 * @deprecated Use configurable-bundle-widget-order-configured-bundle mixin instead.
 */
/**
 * @deprecated Use configurable-bundle-widget-order-configured-bundle-product mixin instead.
 */
.collapsible-list__button {
  background-color: rgba(255, 255, 255, 0);
  color: #E30613;
  cursor: pointer;
}
.collapsible-list__button:hover {
  text-decoration: underline;
}
.collapsible-list__button::after {
  content: attr(data-show);
}
.collapsible-list__button--shown::after {
  content: attr(data-hide);
}
.collapsible-list__button-content {
  display: none;
}

/* fe-sniffer:disabled correct-scss-component-structure */
/**
 * @deprecated Use shop-ui-quote-status-color mixin instead.
 */
/**
 * @deprecated Use tabs-widget-search-tabs mixin instead.
 */
.button {
  -webkit-transition: color 250ms ease-in-out, border-color 250ms ease-in-out, background-color 250ms ease-in-out;
  transition: color 250ms ease-in-out, border-color 250ms ease-in-out, background-color 250ms ease-in-out;
  background-color: #E30613;
  border: solid 1px #E30613;
  border-radius: 2px;
  color: #fff;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  display: inline-block;
  padding: 0.4375rem 1.25rem;
  text-align: center;
  cursor: pointer;
}
@media only screen and (max-width: 769px) {
  .button {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
.button:hover, .button:focus {
  background-color: #b1050f;
  border-color: #b1050f;
  color: #fff;
  text-decoration: none;
}
.button--hollow {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #E30613;
  color: #E30613;
}
.button--hollow:hover, .button--hollow:focus {
  border-color: #E30613;
  color: #fff;
  background-color: #E30613;
}
.button--hollow-icon {
  -webkit-transition: color 250ms ease-in-out;
  transition: color 250ms ease-in-out;
  padding: 0.25rem 0.5rem;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  color: #E30613;
}
.button--hollow-icon:hover, .button--hollow-icon:focus {
  background-color: rgba(255, 255, 255, 0);
  color: #fc9fa5;
}
.button--hollow-second {
  color: #fff;
  border-color: #fff;
}
.button--hollow-second:hover, .button--hollow-second:focus {
  color: #e6e6e6;
  background-color: rgba(255, 255, 255, 0);
  border-color: #e6e6e6;
}
.button--hollow-alternative {
  color: #8f8f8f;
  border-color: #666666;
}
.button--hollow-alternative:hover, .button--hollow-alternative:focus {
  border-color: #8f8f8f;
  color: #fff;
  background-color: #8f8f8f;
}
.button--alternative {
  background-color: #E30613;
  border-color: #E30613;
  color: #fff;
}
.button--alternative:hover, .button--alternative:focus {
  background-color: #b1050f;
  border-color: #b1050f;
}
.button--additional {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
}
.button--additional:hover, .button--additional:focus {
  background-color: #767676;
  border-color: #767676;
}
.button--medium {
  font-size: 0.875rem;
  line-height: normal;
  padding: 0.625rem 1.875rem;
}
@media only screen and (min-width: 768px) {
  .button--medium {
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0.9375rem 1.6875rem;
  }
}
.button--big {
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.9375rem 1.6875rem;
}
@media only screen and (min-width: 768px) {
  .button--big {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }
}
.button--bigger {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.button--form {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.button--tight {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.button--mobile-tight {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
@media only screen and (min-width: 768px) {
  .button--mobile-tight {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}
.button--expand {
  width: 100%;
}
@media only screen and (max-width: 549px) {
  .button--expand-sm-only {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .button--expand-sm-lg {
    width: 100%;
  }
}
@media only screen and (min-width: 550px) {
  .button--checkout {
    min-width: 12.5rem;
  }
}
.button--edit, .button--back {
  -webkit-transition: color 250ms ease-in-out;
  transition: color 250ms ease-in-out;
  color: #666666;
}
.button--edit:hover, .button--edit:focus, .button--back:hover, .button--back:focus {
  color: #666666;
}
.button--back {
  font-weight: 400;
}
.button--main {
  background-color: #E30613;
  border-color: #E30613;
}
.button--main:hover, .button--main:focus {
  background-color: #f9232f;
  border-color: #f9232f;
}
.button--has-icon {
  color: white;
  font-family: "Font Awesome 5 Pro";
}
.button--search {
  width: 2.1875rem;
  height: 2.1875rem;
  padding: 0;
  font-weight: 400;
}
.button--search:before {
  content: "";
}
.button--add-to-cart:before {
  content: "";
  margin-right: 0.875rem;
}
.button--gray {
  background-color: #666666;
  border-color: #666666;
  font-size: 1rem;
}
.button--gray:hover, .button--gray:focus {
  background-color: gray;
  border-color: gray;
}
.button--link {
  color: #0a0a0a;
  font-weight: 400;
  text-decoration: underline;
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  padding: 0;
}
.button--link:hover {
  color: #0a0a0a;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: underline;
  border-color: white;
}
.button--link-icon {
  padding: 0.375rem 0.4375rem;
  font-size: 1rem;
}
.button--link-icon:after {
  content: "";
  font-size: 0.625rem;
  margin-left: 0.4375rem;
  position: relative;
  top: -0.0625rem;
}
@media only screen and (max-width: 769px) {
  .button--link-icon {
    font-size: 1.125rem;
    line-height: 1.3125rem;
  }
}
.button--square, .button--pagination {
  width: 2.125rem;
  height: 2.125rem;
  border: 1px solid #666666;
  padding: 0;
  background: white;
}
.button--square:hover, .button--square:focus, .button--pagination:hover, .button--pagination:focus {
  background-color: #F8F8F8;
  border-color: #666666;
}
.button--square:before, .button--pagination:before {
  color: #666666;
  font-size: 0.9375rem;
}
.button--pagination {
  color: #666666;
  font-size: 0.9375rem;
}
.button--pagination:hover, .button--pagination:focus {
  color: #666666;
  background-color: #F8F8F8;
  border-color: #666666;
}
.button--arrow {
  width: 2.125rem;
  height: 2.125rem;
  border: 1px solid transparent;
  font-size: 1.75rem;
  color: #666666;
  background: transparent;
  padding: 0 0.4375rem;
}
.button--arrow:before {
  content: "";
}
.button--arrow.is-disabled {
  color: #E7E7E7;
  pointer-events: none;
}
.button--arrow:hover, .button--arrow:focus {
  color: #4d4d4d;
  background-color: transparent;
  border-color: transparent;
}
.button--rotate {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.button--grid:before {
  content: "";
}
.button--list:before {
  content: "";
}
.button--info {
  color: white;
  font-size: 0.9375rem;
  background-color: #999999;
  border-color: #999999;
}
.button--info:hover, .button--info:focus {
  background-color: gray;
  border-color: gray;
}
@media only screen and (max-width: 769px) {
  .button--info {
    font-size: 1.125rem;
    line-height: 1.3125rem;
  }
}
.button--clipboard:before {
  content: "";
  font-size: 0.9375rem;
  margin-right: 0.625rem;
}
.button--no-padding {
  padding: 0;
}
.button--transparent {
  background-color: transparent;
}
.button--filter:after {
  content: "";
  margin-left: 0.375rem;
}
.button--open {
  width: 1rem;
  height: 1rem;
  border: 1px solid #666666;
  color: #666666;
  font-size: 1rem;
}
.button--open:hover, .button--open:focus {
  background-color: #F8F8F8;
  border-color: #666666;
}
.button--open:before {
  color: #666666;
  font-size: 0.75rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 0.75rem;
}
.button--open:before {
  content: "";
}
.button--open.is-active:before {
  content: "";
}
.button--wider {
  min-width: 10rem;
}
.button--second {
  -webkit-transition: border-color 250ms ease-in-out, color 250ms ease-in-out;
  transition: border-color 250ms ease-in-out, color 250ms ease-in-out;
  color: #8f8f8f;
  border-color: #666666;
}
.button--second:hover, .button--second:focus {
  background-color: rgba(255, 255, 255, 0);
  border-color: #E30613;
  color: #E30613;
}
.button[disabled], .button--disabled {
  background-color: #999999;
  border-color: #999999;
  color: #fff;
  cursor: not-allowed;
}
.button[disabled]:hover, .button[disabled]:focus, .button--disabled:hover, .button--disabled:focus {
  background-color: #999999;
  border-color: #999999;
}
.button--disabled {
  pointer-events: none;
}
.button--justify {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.button--additional {
  padding: 0.875rem 1.25rem;
  background: #fff;
  border-color: #e7eaee;
  color: #666666;
  -webkit-box-shadow: 0 0.625rem 1.125rem 0 rgba(0, 0, 0, 0.05), 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.04);
          box-shadow: 0 0.625rem 1.125rem 0 rgba(0, 0, 0, 0.05), 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.04);
  font-size: 0.9375rem;
}
.button--tab-gray {
  font-size: 0.9375rem;
  color: #666666;
  background-color: unset;
  border: none;
  border-color: #E7E7E7;
}
.button--tab-gray:hover, .button--tab-gray:focus {
  color: #666666;
  background-color: #dce0e5;
  border-color: transparent;
}
.button--shopping-list {
  position: absolute;
  bottom: 1.375rem;
  left: 1.375rem;
  width: calc(100% - 2.75rem);
}
@media only screen and (min-width: 768px) {
  .button--shopping-list {
    position: static;
    width: auto;
  }
}
.button--no-horizontal-padding {
  padding-left: 0;
  padding-right: 0;
}
.button--tab {
  font-size: 0.8125rem;
  padding: 0.1875rem 0.3125rem;
  line-height: 1.25rem;
  border-radius: 6px 6px 0 0;
  border-bottom: 0;
}
.button--black-border {
  background-color: #fefefe;
  border-color: #666666;
  color: #666666;
}
.button--black-border:hover, .button--black-border:focus, .button--black-border:active {
  background-color: #eae8e8;
  color: #666666;
}
.button--black-border .spinner {
  color: #E30613;
}
.button--success {
  background-color: #E30613;
  border-color: #E30613;
}
.button--success:hover, .button--success:focus {
  background-color: #b1050f;
  border-color: #b1050f;
}
.button--success.button--hollow {
  background-color: #fff;
  border-color: #E30613;
  color: #E30613;
}
.button--success.button--hollow:hover, .button--success.button--hollow:focus {
  background-color: #E30613;
  color: #fff;
}
.button--warning {
  background-color: #fdbe36;
  border-color: #fdbe36;
}
.button--warning:hover, .button--warning:focus {
  background-color: #fcae04;
  border-color: #fcae04;
}
.button--warning.button--hollow {
  background-color: #fff;
  border-color: #E30613;
  color: #E30613;
}
.button--warning.button--hollow:hover, .button--warning.button--hollow:focus {
  background-color: #E30613;
  color: #fff;
}
.button--alert {
  background-color: #E30613;
  border-color: #E30613;
}
.button--alert:hover, .button--alert:focus {
  background-color: #b1050f;
  border-color: #b1050f;
}
.button--alert.button--hollow {
  background-color: #fff;
  border-color: #E30613;
  color: #E30613;
}
.button--alert.button--hollow:hover, .button--alert.button--hollow:focus {
  background-color: #E30613;
  color: #fff;
}